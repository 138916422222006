<template>
  <div class="imgBox min-w-1400">
    <div class="imgBox1 min-w-1400">
      <div class="img1 max-w-1400">
        <img :src="productImg1" :alt="altText" class="imgItem1" />
      </div>
    </div>
    <div class="imgBox2 min-w-1400">
      <div class="img2 max-w-1400">
        <img :src="productImg2" :alt="altText" class="imgItem2" />
      </div>
    </div>
    <div class="imgBox3 min-w-1400">
      <div class="img3 max-w-1400" @click="gotoUser">
        <img :src="productImg3" :alt="altText" class="imgItem3" />
      </div>
    </div>
    <div class="imgBox4 min-w-1400">
      <div class="img4 max-w-1400">
        <img :src="productImg4" :alt="altText" class="imgItem4" />
      </div>
    </div>
  </div>
</template>

<script>
import productImg1 from "@/page/aitdong/components/img/4_1.png";
import productImg2 from "@/page/aitdong/components/img/5_2.png";
import productImg3 from "@/page/aitdong/components/img/5_3.png";
import productImg4 from "@/page/aitdong/components/img/1_8.png";
export default {
  data() {
    return {
      productImg1: productImg1,
      productImg2: productImg2,
      productImg3: productImg3,
      productImg4: productImg4,
      altText: "图片",
    };
  },
  created() {
    this.gotoTop();
  },
  methods: {
    gotoTop() {
      window.scrollTo({ top: 0, behavior: "smooth" });
    },

    gotoUser() {
      this.$router.push("/user");
    },
  },
};
</script>

<style scoped>
.imgBox {
  display: flex;
  flex-direction: column;
  background-color: #f6f6f6;
}
.imgBox1,
.imgBox4 {
  display: flex;
  justify-content: center;
  width: 100%;
  min-width: 1400px;
  background-color: "";
}
.imgBox2 {
  display: flex;
  justify-content: center;
  max-width: 1400px;
  padding-top: 40px;
  padding-bottom: 20px;
  height: 6320px;
  background-color: #ffffff;
  border-radius: 32px;
  margin-top: -130px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 80px;
}

.imgBox3 {
  display: flex;
  justify-content: center;
  width: 100%;
  min-width: 1400px;
  background-color: "";
}
.imgBox1{
  margin-top: 130px;
}
img {
  display: block;
}
.img1,
.img4 {
  width: 100%;
  overflow: hidden;
  margin-left: 0;
  margin-right: 0;
}

.img3,
.img2 {
  margin-left: 45px;
  margin-right: 45px;
}
.imgItem1 {
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;
}
.imgItem2 {
  max-width: 100%;
}
.imgItem3 {
  max-width: 100%;
  margin-top: 50px;
  margin-bottom: 100px;
}
.imgItem4 {
  width: 100%;
  overflow: hidden;
  margin-left: 0;
  margin-right: 0;
}
</style>
